import React from 'react'

const IconLoadingSpinner: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...other }) => {
  return (
    <div
      className={
        'h-8 w-8 animate-spin rounded-full border-4 border-gray-muted border-t-brand-gold' +
        (className ? ` ${className}` : '')
      }
      {...other}
    />
  )
}

export default IconLoadingSpinner
