import Evaporate from 'evaporate'
import Cookies from 'js-cookie'
import delay from 'lodash/delay'
import React, { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import createHash from 'sha.js'
import SparkMD5 from 'spark-md5'

import FormError from '~/components/typography/FormError'
import Label from '~/components/typography/Label'
import useGlobal from '~/hooks/useGlobal'
import PlaceholderPng from '~/static/img/square.png'
import getImgURL from '~/utils/getImgUrl'
import IconLoadingSpinner from '../icons/IconLoadingSpinner'

const ACCEPTED_FILES = {
  'image/*': ['.jpg', '.jpeg', '.png'],
}

interface Props {
  /** Destination directory of upload (artists, etc) */
  dest: string
  /** If `true`, this field will be disabled, even if the form as a whole is currently enabled */
  disabled?: boolean
  /** If `true`, the label will have a `*` at the end */
  isMandatory?: boolean
  /** Thumbnail URL of original image */
  displayValue?: string
  /** The label of the input */
  label?: string
  /** The name of this input, must be snake_case */
  name: string
  /** The link to full image */
  original?: string
  /** ClassName override  */
  className?: string
  onChange?: (e: any) => void
  onChangeAsync?: (data: any, onDone: (img: any) => void) => void
  errorStr?: string
  maxWidth?: string
  allowedTypes?: string
  isLoading?: boolean
  notSquare?: boolean
  noClick?: boolean
  infoText?: string
  innerDisplay?: (open: () => void) => React.ReactNode
  bigText?: boolean
  UploadLogo?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  setImageLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const InputImageUpload: React.FC<Props> = ({
  allowedTypes,
  className = '',
  dest,
  disabled = false,
  displayValue,
  isMandatory = false,
  onChangeAsync,
  onChange,
  errorStr,
  label,
  name,
  original,
  isLoading,
  notSquare,
  noClick,
  infoText,
  innerDisplay,
  bigText,
  UploadLogo,
  setImageLoading,
}) => {
  const global = useGlobal()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [fileReaderSrc, setFileReaderSrc] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState('')
  // used to track if the field is initial form value or not
  const [objKey, setObjKey] = useState<string | null>(null)
  const [inputError, setInputError] = useState('')

  const id_ = `id_${name}`
  const errorId = `${id_}_error`

  const error = useCallback((msg: string) => {
    setIsUploading(false)
    setInputError(`ERROR: ${msg}`)
  }, [])

  useEffect(() => {
    setInputError(errorStr || '')
  }, [errorStr])

  const request = (
    method: string,
    url: string,
    data: any,
    headers: any,
    el: any,
    cb: any
  ) => {
    const req = new XMLHttpRequest()
    req.open(method, url, true)

    Object.keys(headers).forEach((key) => {
      req.setRequestHeader(key, headers[key])
    })

    req.onload = () => {
      cb(req.status, req.responseText)
    }

    req.onerror = () => {
      window.alert('Sorry, failed to upload file.')
    }

    req.onabort = () => {
      window.alert('Sorry, failed to upload file.')
    }

    req.send(data)
  }

  const finishUpload = (objectKey: string) => {
    objectKey = objectKey.replace('media/', '')
    setObjKey(objectKey)
    if (onChange) {
      delay(() => onChange(objectKey), 500)
    }
    setIsUploading(false)
  }

  const computeMd5 = (data: ArrayBuffer) => {
    return btoa(SparkMD5.ArrayBuffer.hash(data, true))
  }

  const computeSha256 = (data: string | ArrayBuffer | null) => {
    return createHash('sha256')
      .update(data as string, 'utf-8')
      .digest('hex')
  }

  const initiateMultipartUpload = (
    signingUrl: string,
    objectKey: string,
    awsKey: string,
    awsRegion: string,
    awsBucket: string,
    cacheControl: string,
    contentDisposition: string,
    acl: any,
    serverSideEncryption: string,
    file: File
  ) => {
    // Enclosed so we can propagate errors to the correct `element` in case of failure.
    const getAwsV4Signature = (
      signParams: object,
      signHeaders: object,
      stringToSign: string,
      signatureDateTime: string
    ) =>
      new Promise<string>((resolve, reject) => {
        const form = new FormData()
        form.append('to_sign', stringToSign)
        form.append('datetime', signatureDateTime)
        const headers = { 'X-CSRFToken': Cookies.get('csrftoken') }
        request(
          'POST',
          signingUrl,
          form,
          headers,
          {},
          (status: number, response: string) => {
            switch (status) {
              case 200:
                resolve(JSON.parse(response).s3ObjKey)
                resolve(response)
                break
              default:
                error('Could not generate AWS v4 signature.')
                reject()
                break
            }
          }
        )
      })

    const generateAmazonHeaders = (
      _acl: any,
      _serverSideEncryption: string
    ) => {
      // Either of these may be null, so don't add them unless they exist:
      const headers: any = {}
      if (_acl) {
        headers['x-amz-acl'] = _acl
      }
      if (serverSideEncryption) {
        headers['x-amz-server-side-encryption'] = _serverSideEncryption
      }
      return headers
    }

    Evaporate.create({
      // signerUrl: signingUrl,
      customAuthMethod: getAwsV4Signature,
      // eslint-disable-next-line
      aws_key: awsKey,
      bucket: awsBucket,
      awsRegion,
      computeContentMd5: true,
      cryptoMd5Method: computeMd5,
      cryptoHexEncodedHash256: computeSha256,
      partSize: 20 * 1024 * 1024,
      logging: false,
      s3FileCacheHoursAgo: null,
      onlyRetryForSameFileName: false,
      allowS3ExistenceOptimization: false,
    }).then((evaporate) => {
      evaporate
        .add({
          name: objectKey,
          file,
          contentType: file.type,
          xAmzHeadersAtInitiate: generateAmazonHeaders(
            acl,
            serverSideEncryption
          ),
          notSignedHeadersAtInitiate: {
            'Cache-Control': cacheControl,
            'Content-Disposition': contentDisposition,
          },
        })
        .then(
          (awsS3ObjectKey: string) => {
            finishUpload(awsS3ObjectKey)
          },
          (reason: string) => error(reason)
        )
    })
  }

  const onDrop = (acceptedFiles: any[], rejectedFiles: any[]) => {
    if (rejectedFiles.length > 0) {
      window.alert('WARNING: Some files have been rejected:')
      window.alert(rejectedFiles)
    }

    const data = {
      dest,
      file: acceptedFiles[0],
      name: acceptedFiles[0].name,
      size: acceptedFiles[0].size,
      type: acceptedFiles[0].type,
      src: URL.createObjectURL(acceptedFiles[0]),
    }

    if (onChangeAsync) {
      onChangeAsync(data, uploadReq)
      return
    }
    uploadReq(data)
  }

  const uploadReq = (image: any) => {
    // turn image into base64 string so that we can render it as a preview
    // while uploading
    const reader = new FileReader()
    reader.onload = (e: any) => {
      if (e.target) {
        setFileReaderSrc(e.target.result)
      }
    }
    reader.readAsDataURL(image.file)

    fetch(`${global.ENV.DJANGO_URL}/s3direct/get_upload_params/`, {
      method: 'POST',
      body: JSON.stringify(image),
    })
      .then((jsonRes) => {
        jsonRes.json().then((res) => {
          if (res.error) {
            setInputError(res.error)
            setIsUploading(false)
            if (setImageLoading) {
              setImageLoading(false)
            }
            return
          }
          setUploadError('')
          setIsUploading(true)
          initiateMultipartUpload(
            `${global.ENV.DJANGO_URL}/s3direct/get_aws_v4_signature/`,
            res.object_key,
            res.access_key_id,
            res.region,
            res.bucket,
            res.cache_control,
            res.content_disposition,
            res.acl,
            res.server_side_encryption,
            image.file
          )
        })
      })
      .catch((err) => {
        window.alert('Network error')
        window.alert(err)
        setUploadError('Failed to upload file. Please try again later.')
        setIsUploading(false)
      })
  }

  // if we have a new uploaded image and the user presses save and the form
  // becomes temporarily disabled, we want to still show the new image and
  // not jump back to the old image while the form is loading:
  const displaySrc = fileReaderSrc || displayValue

  let textStyle = 'flex text-center font-serif text-p4 text-text-muted-2x '
  if (bigText) {
    textStyle = 'flex text-center font-sansSerif text-p2 mx-auto max-w-[250px] '
  }

  return (
    <div
      data-test="InputFileUpload"
      className={'relative mb-4 ' + (notSquare ? 'h-full ' : '')}
    >
      {label && (
        <Label>
          {label}
          {isMandatory ? ' *' : ''}
        </Label>
      )}
      {!!disabled && (
        <div
          className={
            'relative w-full max-w-full cursor-not-allowed border-2 border-dashed border-gray-300 opacity-80 ' +
              (notSquare ? 'h-full ' : '') +
              className || ''
          }
        >
          {!notSquare && (
            <img
              src={PlaceholderPng}
              style={{ width: '100%' }}
              alt=""
            />
          )}
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full p-2">
            {displayValue && (
              <div
                className="h-full w-full bg-cover bg-center"
                style={{ backgroundImage: `url(${displaySrc})` }}
              />
            )}
            <input
              type="hidden"
              name={name}
              value={objKey || ''}
            />
          </div>
          {(isUploading || isLoading) && (
            <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-black bg-opacity-75" />
          )}
        </div>
      )}
      {!disabled && (
        <React.Fragment>
          <Dropzone
            onDrop={(acc, rej) => onDrop(acc, rej)}
            accept={ACCEPTED_FILES}
            noClick={noClick}
          >
            {({ getRootProps, getInputProps, open }) => (
              <div
                className={
                  'max-h-auto relative ml-auto mr-auto w-full max-w-full border-2 border-dashed border-gray-300 ' +
                    (noClick ? '' : 'cursor-pointer ') +
                    (notSquare ? 'h-full ' : '') +
                    className || ''
                }
                {...getRootProps()}
              >
                {!notSquare && (
                  <img
                    src={PlaceholderPng}
                    style={{ width: '100%' }}
                    alt=""
                  />
                )}
                <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 p-2">
                  {!objKey && !displayValue && !!UploadLogo && (
                    <UploadLogo className="h-[40px] w-[40px] fill-brand-bg-border transition-all hover:fill-icon-dark" />
                  )}
                  {!objKey && !displayValue && !innerDisplay && (
                    <div className={textStyle}>
                      {infoText
                        ? infoText
                        : allowedTypes
                        ? allowedTypes
                        : 'JPG or PNG files, max. file size 10MB'}
                    </div>
                  )}
                  {!objKey &&
                    !displayValue &&
                    innerDisplay &&
                    innerDisplay(open)}
                  {!objKey && displayValue && (
                    <div
                      className="h-full w-full bg-cover bg-center"
                      style={{ backgroundImage: `url(${displayValue})` }}
                    />
                  )}
                  {objKey && fileReaderSrc && (
                    <div style={{ width: '100%', height: '100%' }}>
                      <div
                        className="h-full w-full bg-cover bg-center"
                        style={{
                          backgroundImage: `url(${getImgURL(
                            global.ENV.IMAGEKIT_PATH,
                            objKey
                          )}?tr=w-400,h-400)`,
                        }}
                      />
                      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center" />
                    </div>
                  )}
                  <input
                    type="hidden"
                    // name={`${name}-file`}
                    {...getInputProps()}
                  />
                  <input
                    ref={inputRef}
                    type="hidden"
                    name={name}
                    value={objKey || ''}
                  />
                </div>
                {(isUploading || isLoading) && (
                  <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
                    <IconLoadingSpinner />
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </React.Fragment>
      )}

      <FormError
        id={errorId}
        error={inputError}
      />
      {!!original && (
        <a
          href={original}
          target="_blank"
          rel="noopener noreferrer"
        >
          See Image
        </a>
      )}
    </div>
  )
}

export default InputImageUpload
